<template>
    <div class="mortgage-page container" :class="[{ 'stopAnimation': !getProgressAxios.statusResponseAxios }]">
        <h1 showScroll="title" :class="[{ 'moveTop': elementVisibleScroll.title.visible }]">{{ $route.name }}</h1>
        <div class="row mortgage-rates-block">
            <separator class="col-md-11" :show="true" />
            <separator class="col-md-11 offset-md-1" :show="true" />
            <div class="col-md-11 mortgage-rates">
                <h3 class="mortgage-rates-title" showScroll="interestRatesTitle"
                    :class="[{ 'moveTop': elementVisibleScroll.interestRatesTitle.visible }]">
                    {{ arResult.arProperty.INTEREST_RATES_TITLE.VALUE }}
                </h3>
                <div class="mortgage-rates-list">
                    <div class="interest-rates-item"
                        v-for="(item, i) in arResult.arProperty.INTEREST_RATES_LIST['~VALUE']" :key="i"
                        :showScroll="'interestRatesList' + i"
                        :class="[{ 'moveTop': elementVisibleScroll['interestRatesList' + i] && elementVisibleScroll['interestRatesList' + i].visible }]"
                        v-html="item.TEXT">
                        {{item}}
                    </div>
                </div>
            </div>
            <div class="col-md-11 offset-md-1 mortgage-rates">
                <h3 class="mortgage-rates-title" showScroll="creditConditionsTitle"
                    :class="[{ 'moveTop': elementVisibleScroll.creditConditionsTitle.visible }]">
                    {{ arResult.arProperty.CREDIT_CONDITIONS_TITLE.VALUE }}
                </h3>
                <div class="mortgage-rates-list">
                    <div class="interest-rates-item"
                        v-for="(item, i) in arResult.arProperty.CREDIT_CONDITIONS_LIST['~VALUE']" :key="i"
                        :showScroll="'creditConditionsList' + i"
                        :class="[{ 'moveTop': elementVisibleScroll['creditConditionsList' + i] && elementVisibleScroll['creditConditionsList' + i].visible }]"
                        v-html="item.TEXT">
                        {{item}}
                    </div>
                </div>
            </div>
        </div>

        <mortgage-calculator :element-visible-scroll="elementVisibleScroll" />

        <div class="tax-block">
            <separator :show="true" />
            <div class="row">
                <div class="col-xxl-5 col-xl-7 col-md-8">
                    <h2 showScroll="titleTaxDeduction"
                        :class="[{ 'moveTop': elementVisibleScroll.titleTaxDeduction.visible}]">
                        {{arResult.arProperty.TITLE_TAX_DEDUCTION.VALUE}}
                    </h2>
                </div>

                <div class="offset-xxl-7 offset-xl-5 col-xl-11 offset-md-3 col-md-12 tax-desc tax-deduction-desc">
                    <div class="" v-for="(item, i) in arResult.arProperty.DESC_TAX_DEDUCTION['~VALUE']" :key="i"
                        :showScroll="'descTax' + i"
                        :class="[{ 'moveTop': elementVisibleScroll['descTax' + i] && elementVisibleScroll['descTax' + i].visible}]"
                        v-html="item.TEXT">
                        {{item}}
                    </div>
                </div>

                <separator class="col-xl-16 offset-xl-7 col-md-23" :show="true" />
                <h3 class="col-xl-4 offset-xl-7 col-md-8 subtitleTaxDeduction" showScroll="subtitleTaxDeduction"
                    :class="[{ 'moveTop': elementVisibleScroll.subtitleTaxDeduction.visible}]">
                    {{arResult.arProperty.SUBTITLE_TAX_DEDUCTION.VALUE}}
                </h3>
                <div class="list-tax deduction col-xl-11 offset-xl-1 offset-md-3 col-md-12">
                    <ul class="row">
                        <li class="text-square" v-for="(item, i) in arResult.arProperty.LIST_TAX_DEDUCTION['~VALUE']"
                            :key="i" :showScroll="'listTax' + i"
                            :class="[{ 'moveTop': elementVisibleScroll['listTax' + i] && elementVisibleScroll['listTax' + i].visible}]"
                            v-html="item.TEXT">
                            {{item}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tax-block">
            <separator :show="true" />
            <div class="row">
                <div class="col-xxl-5 col-xl-7 col-md-8">
                    <h2 showScroll="titleMotherCapital"
                        :class="[{ 'moveTop': elementVisibleScroll.titleMotherCapital.visible}]">
                        {{arResult.arProperty.TITLE_MOTHER_CAPITAL.VALUE}}
                    </h2>
                </div>
                <div class="col-xl-11 offset-xxl-7 offset-xl-5 offset-md-3 col-md-12">
                    <div showScroll="subtitleMotherCapital" v-if="arResult.arProperty.DESC_MOTHER_CAPITAL['~VALUE']"
                        v-html="arResult.arProperty.DESC_MOTHER_CAPITAL['~VALUE'].TEXT" class="tax-desc title"
                        :class="[{ 'moveTop': elementVisibleScroll.subtitleMotherCapital.visible}]" />

                    <div class="list-tax">
                        <ul class="row">
                            <li class="text-square"
                                v-for="(item, i) in arResult.arProperty.LIST_MOTHER_CAPITAL['~VALUE']" :key="i"
                                :showScroll="'listMother' + i"
                                :class="[{ 'moveTop': elementVisibleScroll['listMother' + i] && elementVisibleScroll['listMother' + i].visible}]"
                                v-html="item.TEXT">
                                {{item}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-11 offset-xl-12 offset-md-11 col-md-12 mother-capital">
                    <separator :show="true" />
                    <div showScroll="subtitleMotherCapitalDocs"
                        v-if="arResult.arProperty.DESC_MOTHER_CAPITAL_DOCS['~VALUE']"
                        v-html="arResult.arProperty.DESC_MOTHER_CAPITAL_DOCS['~VALUE'].TEXT" class="tax-desc title"
                        :class="[{ 'moveTop': elementVisibleScroll.subtitleMotherCapitalDocs.visible}]" />

                    <div class="list-tax">
                        <ul class="row">
                            <li class="text-square"
                                v-for="(item, i) in arResult.arProperty.LIST_MOTHER_CAPITAL_DOCS['~VALUE']" :key="i"
                                :showScroll="'listMotherDocs' + i"
                                :class="[{ 'moveTop': elementVisibleScroll['listMotherDocs' + i] && elementVisibleScroll['listMotherDocs' + i].visible}]"
                                v-html="item.TEXT">
                                {{item}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { getClBlockElement } from "../../../jsFunction/getCIBlockElement";
import { showElementScroll } from "../../../jsFunction/showElementScroll";
import { seo } from "../../../jsFunction/seo";
import Separator from "../../layout/Separator";
// import MortgageCalculator from "./parts/MortgageCalculator";
import MortgageCalculator from "../../layout/projects/MortgageCalculator.vue";

export default {
    name: "MortgagePage",
    components: { Separator, MortgageCalculator },
    mixins: [getClBlockElement, showElementScroll ],
    data() {
        return {
            changeAxios: false,
            arResult: {
                arProperty: {
                    MATERIAL_WALL: [],
                    MATERIAL_ID_SECTION: [],
                    TITLE_TAX_DEDUCTION: [],
                    DESC_TAX_DEDUCTION: [],
                    SUBTITLE_TAX_DEDUCTION: [],
                    LIST_TAX_DEDUCTION: [],
                    TITLE_MOTHER_CAPITAL: [],
                    DESC_MOTHER_CAPITAL: [],
                    LIST_MOTHER_CAPITAL: [],
                    DESC_MOTHER_CAPITAL_DOCS: [],
                    LIST_MOTHER_CAPITAL_DOCS: [],
                    INTEREST_RATES_TITLE: [],
                    INTEREST_RATES_LIST: [],
                    CREDIT_CONDITIONS_TITLE: [],
                    CREDIT_CONDITIONS_LIST: [],
                    WORKING_SCHEME: [],
                    PROJECT_ID: []
                }
            },
            elementVisibleScroll: { // зписывам в объект имена showScroll элементов
                title: {}, monthPayment: {}, btnConsul: {}, btnConsul2: {}, 
                projectsBtn1: {}, navBtn: {}, textTable: {}, descCalculator: {}, rowTable: {}, interestRatesTitle: {}, interestRatesList: {}, 
                subtitleTaxDeduction: {},titleTaxDeduction: {}, titleConfigurator: {}, inputCalculator: {}, monthPaymentPrice: {}, textResultPrice: {}, 
                materialBlock: {}, titleScheme: {}, subtitleMotherCapital: {},titleMotherCapital: {}, descMotherCapital: {}, descMotherCapitalDocs: {}, 
                subtitleMotherCapitalDocs: {}, creditConditionsList: {}, creditConditionsTitle: {}, titleCalculator: {}
            },

            arElementsShow: [],
            arSort: {
                'SORT': 'ASC'
            },
            arFilter: {
                'IBLOCK_ID': 22, 'ACTIVE': 'Y',
            },
            arSelect: [
                'NAME', 'CODE', 'PREVIEW_PICTURE', 'DETAIL_PAGE_URL',
            ],
            arNuvParam: {},
            snakePart: ['col-13 offset-7 tail-body-right',
                'col-18 offset-2 part-right tail-body-left tail-head-right',
                'col-10 offset-2 tail-head-left part-left-inside',
                'col-8 tail-body-right',
                'col-13 offset-7 part-right tail-body-left tail-head-right',
                'col-8 offset-7 part-left-inside tail-head-left',
                'col-5 part-right tail-bo   dy-right',
                'col-18 offset-2 part-left tail-head-right',
            ],
            widthOrder: ['col-xxl-7 ', 'col-xxl-5 col-md-12', 'col-md-11'],
            tableProperty: [
                'ANALYSIS_SECTION_TABLE', 'FOUNDATION_TABLE', 'COMMUNICATION_TABLE', 'PARTITIONS_TABLE', 'JUMPERS_TABLE', 'OVERLAP_TABLE',
                'STAIRS_TABLE', 'ROOF_TABLE'
            ],
            priceProperty: 'PRICE_MATERIAL',
        }
    },
    methods: {
        handleScroll() {
            let visibleStatus = showElementScroll.methods.comparisonPosition(this.arElementsShow);   // получаем статус видимости элементов на экранен
            this.setObjectAnimation(visibleStatus);                                                 // закидываем полученный массив на обработку под реактивность Vue
        },
        startAnimation() {                                                                            // запуск анимации видимых элементов
            this.arElementsShow = showElementScroll.methods.GetElements('.mortgage-page');   // получаем позиции элементов на странице для анимации
            let visibleStatus = showElementScroll.methods.comparisonPosition(this.arElementsShow);
            this.setObjectAnimation(visibleStatus);
            this.$store.dispatch('receiveCheckPosition');
        },
        setObjectAnimation(visibleStatus) {                   // обработка объекта с позициями элементов под реактивность Vue
            for (let item in this.arElementsShow) {
                this.$set(this.elementVisibleScroll, item, this.arElementsShow[item]);
                if (typeof visibleStatus === 'boolean') {
                    this.$set(this.elementVisibleScroll[item], 'visible', visibleStatus);
                } else {
                    this.$set(this.elementVisibleScroll[item], 'visible', visibleStatus[item].visible);
                }
            }
        },
        handleResize() {
            window.setTimeout(() => {
                this.startAnimation();
            }, 100);
        },
    },
    computed: {
        getProgressAxios() {
            return this.$store.getters.getProgressAxios; // чекаем статус Интро
        },
        bitrixStore() {
            return this.$store.getters.cacheData;
        }
    },
    watch: {
        'getProgressAxios.statusResponseAxios'(value) {
            this.changeAxios = true;
            if (value) {
                this.startAnimation();
            }
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);

        this.$store.dispatch('receiveAppClass', { appClass: 'bgColorLightGray', showSeparator: true });

        if (!this.bitrixStore[this.arFilter.IBLOCK_ID]) {
            getClBlockElement.methods.GetList(this.arSort, this.arFilter, false, this.arNuvParam, this.arSelect).then((data) => {
                this.arResult = data.ITEMS[0];
                seo.methods.set(this.arResult, 'detail');

                this.$store.dispatch('receiveCacheData', { id: this.arFilter.IBLOCK_ID, cacheData: data })
            })
        } else {
            this.arResult = this.bitrixStore[this.arFilter.IBLOCK_ID].ITEMS[0];
            seo.methods.set(this.arResult, 'detail');
        }
    },

    mounted() {
        if (!this.changeAxios) {
            this.startAnimation()
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style scoped></style>