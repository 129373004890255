<template>
    <div class="mortgage-price">
        <separator :show="true" />

        <div class="row">
            <h2 class="caltulator-title col-xxl-9 col-xl-10 order-xl-1 order-1" showScroll="titleCalculator"
                :class="[{ 'moveTop': elementVisibleScroll.titleCalculator.visible }]">
                Рассчитать ипотеку
            </h2>
            <div class="calsulator-desc col-xxl-11 order-xl-2 order-3 offset-xxl-3 offset-xl-2 col-xl-11"
                showScroll="descCalculator" :class="[{ 'moveTop': elementVisibleScroll.descCalculator.visible }]">
                наша компания аккредитована во&nbsp;всех банках&nbsp;РФ
            </div>
            <div class="col-xxl-9 col-xl-10 order-xl-3 order-2 input-col">
                <div class="input-calculator">
                    <div class="input-calculator__top">
                        <div class="title">сумма ипотеки</div>
                        <span class="calculator-temp-value">{{ Number(initialPayment).toLocaleString() }} ₽</span>
                    </div>
                    <input v-model="initialPayment" type="range" min="100000" max="30000000" step="10000"
                        class="input-calculator__progress" style="--min: 100000; --max: 30000000;"
                        @input="changeProgress($event)" />
                    <div class="input-calculator__bottom">
                        <span>100 тыс. ₽</span>
                        <span>30 млн. ₽</span>
                    </div>
                </div>
                <div class="input-calculator">
                    <div class="input-calculator__top">
                        <div class="title">срок ипотеки</div>
                        <span class="calculator-temp-value">{{ period }} {{ periodText }}</span>
                    </div>
                    <input v-model="period" type="range" min="5" max="30" class="input-calculator__progress" step="1"
                        style="--min: 5; --max: 30;" @input="changeProgress($event)" />
                    <div class="input-calculator__bottom">
                        <span>5 лет</span>
                        <span>30 лет</span>
                    </div>
                </div>
                <div class="input-calculator">
                    <div class="input-calculator__top">
                        <div class="title">ставка</div>
                        <span class="calculator-temp-value">{{ stake }}6%</span>
                    </div>
                    <input v-model="stake" type="range" min="0.1" max="15" step="0.1" class="input-calculator__progress"
                        style="--min: 0.1; --max: 15;" @input="changeProgress($event)" />
                    <div class="input-calculator__bottom">
                        <span>0,1%</span>
                        <span>15%</span>
                    </div>
                </div>
            </div>

            <div class="col-xxl-11 col-xl-11 order-4 offset-xxl-3 offset-xl-2 result-price">
                <p class="month-payment-text" showScroll="monthPayment"
                    :class="[{ 'moveTop': elementVisibleScroll.monthPayment.visible }]">ежемесячный платеж*</p>
                <div class="month-payment-price" showScroll="monthPaymentPrice"
                    :class="[{ 'moveTop': elementVisibleScroll.monthPaymentPrice.visible }]">
                    {{ resultPrice.toLocaleString() }} ₽*
                </div>
                <p class="text-result-price" showScroll="textResultPrice"
                    :class="[{ 'moveTop': elementVisibleScroll.textResultPrice.visible }]">
                    **Расчет предварительный, по&nbsp;средней ставке. Точная ставка и&nbsp;сумма ипотеки будут
                    определены при&nbsp;оформлении договора. Банк вправе отказать в&nbsp;выдаче ипотеки
                    без&nbsp;объяснения причин.
                </p>
                <base-button :text="'консультация'" :link="'javascript:void(0);'" :typeBtn="'base-btn revers'"
                    showScroll="btnConsul2" :class="[{ 'show-button': elementVisibleScroll.btnConsul2.visible }]"
                    @clickBtn="openCallBackForm(5, 'консультация')" />
            </div>

        </div>
    </div>
</template>

<script>
// import { ref } from "vue";
import Separator from "../Separator"
import BaseButton from "../BaseButton";

export default {
    name: "MortgageCalculator",
    components: { BaseButton, Separator },
    props: {
        rangeParams: [Object, Array],
        elementVisibleScroll: Object,
    },
    data() {
        return {
            arResult: [],
            showAfterIntro: true,
            resultPrice: 0,
            initialPayment: 1440000,
            period: 14,
            periodText: 'лет',
            stake: 6,
        }
    },
    computed: {
        bitrixStore() {
            return this.$store.getters.cacheData;
        }
    },
    watch: {
        resultPrice: function () {
            this.calculationFormula();
        }
    },
    methods: {
        yearText() {
            let txt, count = this.period % 100;
            count >= 5 && count <= 20 ? txt = 'лет' : count = count % 10, count == 1 ? txt = 'год' : (count >= 2 && count <= 4) ? txt = 'года' : txt = 'лет';
            this.periodText = txt;
        },
        changeProgress(event) {
            event.target.style.setProperty('--value', event.target.value);
            this.yearText(this.period);
            this.calculationFormula();
        },
        openCallBackForm(id, title) {
            let getOnlyFields = 'ALL',
                sort = 's_sort',
                order = 'asc',
                arFilter = {
                    'ACTIVE': 'Y'
                };
            this.$store.dispatch('receiveParamForm', {
                id: id, getOnlyFields: getOnlyFields, sort: sort, order: order, arFilter: arFilter, title: title
            });
        },
        created(data) {
            this.arResult = data.ITEMS;
            this.calculationFormula();
            setTimeout(() => {
                this.$emit('created');
            }, 200)
        },
        calculationFormula() {
            this.resultPrice = 0;
            let i = this.stake / 1200;
            let temp = Math.pow((1 + i), this.period);
            this.resultPrice = Math.round(this.initialPayment * ((i * temp) / (temp - 1)));
        }
    },
    created() {

    },
    mounted() {
        for (let e of document.querySelectorAll('input[type="range"].input-calculator__progress')) {
            e.style.setProperty('--value', e.value);
            e.style.setProperty('--min', e.min == '' ? '0' : e.min);
            e.style.setProperty('--max', e.max == '' ? '100' : e.max);
            e.addEventListener('input', () => e.style.setProperty('--value', e.value));
        }
        this.calculationFormula();
    }


}
</script>

<style scoped></style>